html, body {
    font-family: 'Roboto';
    margin: 0px;
}

html {
    height: 100%;
}

.main-content{
    height: 100vh;
}

.mobile{
    display: none;
}

.loading{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
}

.separation {
    color: darkgray;
}

.tramitar{
    display: flex;
    margin-right: 4%;
    justify-content: flex-end;
}

.client-orders-table {
    width: 90%;
}
/* Top header */
.top-header {
    width: 100%;
    color: #6b6b6b;
    display: flex;
    background-color: #f8f8f8;
    align-items: center;
    justify-content: space-around;
    height: 40px;
}

.top-header-social-icons{
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: baseline;
}

.top-header-social-icons ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0 0 0 20px;
    line-height: 1.8em;
    overflow: auto;
    font-size: 16px!important;
    line-height: 1.8em;
}

.top-header-social-icon i{
    color: #6b6b6b;
    font-size: larger;
}
.top-header-social-icon {
    margin-right: 27px;
}

/* Main header */
.main-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-header hr{
    width: 99%;
}

.main-header-scroll {
    box-shadow: 0 1px 5px rgba(0,0,0,.2);
    display: flex;
    position: fixed;
    z-index: 99;
    background-color: white;
    justify-content: space-around;
    width: 100%;
    top: 0;
}

.main-header-scroll-search {
    width: 33%;
    margin-top: auto;
    margin-bottom: auto;
}

.main-header-scroll-icons {
    margin-top: auto;
    margin-bottom: auto;
}

.main-header-scroll-img img{
    max-width: 99px;    
}

.route-title{
    margin-left: 1%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.route-title h2 {
    margin-right: 3px;
}

.route-title-first {
    cursor: pointer;
}
.route-title-first:hover {
    color: #b33d72;
}

.cart-response{
    width: 80%;
    margin: auto;
    margin-top: 11em;
    margin-bottom: 11em;
}

.main-header-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
}

.main-header-search{
    width: 72%;
}

.main-header-search-container{
    width: 25%;
}

.main-header-img-mobile {
    display: none;
    transition: all 0.5s;
}

.main-header-img{
    width: 20%;
}

.main-header-img a{
    display: flex;
    justify-content: center;
}

.main-header-img img{
    margin: auto;
    width: 72%;
}

.main-header-icons{
    width: 25%;
    display: flex;
    justify-content: space-around;
}

.main-header-icons a{
    text-decoration: none;
    color: #6b6b6b;
    font-size: larger;
}

.main-header-icons a:hover{
    color: #bd32e7;
}

.main-header-icons a i{
    margin-right: 7px;
}

.header-navbar{
    width: 100%;
}

.main-header-container ul {
    display: flex;
    list-style: none;
    margin: 0;
    overflow: auto;
    font-size: 24px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.main-header-container ul li{
    margin: 0 20px 0 20px;
    cursor: pointer;
    color: #6b6b6b;
}

.main-header-container ul li:hover{
    color: #3d4c6f;
}

.main-header-container ul{
    overflow: visible;
}

.dropdown {
    position: relative;
    display: inline-block;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 99;
}
  
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown-content-image {
      max-width: 200px;
  }




.categories-root{
    display: 'flex';
    justify-content: 'space-around';
    flex-wrap: 'wrap';
    overflow: 'hidden';
    width: 80%;
    margin: auto;
    margin-top: 20px;
}

.categories-gridList{
    width: 500;
    height: 450;
}

.footer-container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    width: 88%;
}

.footer-container ul{
    padding: 0;
    list-style-type: none;
}

.footer{
    background-color: #af698947;
    padding-bottom: 60px;
    bottom: 0;
    width: 100%;
}

.footer h4{
    color: #2f3951;
}

.footer_a{
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 6px;
    color: #2f3951;
}

.footer_a i{
    margin-right: 8px;
}

.footer_a a{
    text-decoration: none;
    color: #2f3951;
}

.footer_a a:hover{
    color: #af6989;
}

.footer_a:hover{
    text-decoration: none;
    cursor: pointer;
    color: #af6989;
}

.swal2-html-container{
    font-size: small;
}

.categorie-grid{
    cursor: pointer;
}

.categorie-grid img{
    transition: all 0.5s;
}


.categorie-grid:hover img{
    width: 120%;
    filter: brightness(50%);
}

.articles{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.article-description{
    min-height: 100px;
  }

.article-card{
    margin: 10px;
    max-width: 345px;
    min-width: 345px;
}

.article-card-media{
    height: 240px;
}

/* Article detail */

.article-detail {
    width: 80%;
    display: flex;
    margin: auto;
}

.article-img {
    max-width: 50%;
    min-width: 50%;
}

.article-img img{
    max-width: 80%;
}

.article-data{
    color: #6b6b6b;
}

.article-form-control{
    width: 80%;
    
}

.article-detail-form{
    display: flex;
    flex-direction: column;
}

.article-detail-button{
    margin-top: 10px;
}


.admin-form {
    display: flex;
    flex-direction: column;
}

.admin-content {
    width: 22%;
    margin: auto;
    margin-top: 29vh;
}

.admin-content-wrp{
    width: 22%;
    margin: auto;
    margin-top: 8vh;
    margin-bottom: 8vh;
}

.admin-form-button{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.add-button-div {
    position: fixed;
    bottom: 0;
    right: 0;
}

.add-button-div svg{
    font-size: 4.1875rem !important;
}

.MuiTableRow-root[level="1"]{
    background-color: #f2f2f2;
}

.MuiIconButton-label {
    color: #3d4c6f;
}

.create-title{
    color: #515151;
}

.create-category-container{
    padding: 20px;
    text-align: center;
}

.create-category-form{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
}

.create-category-subform{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.form-checks{
    display: flex;
    flex-direction: row;
}

.create-category-form-buttons{
    display: flex;
    justify-content: space-evenly;
    margin-top: 22px;
}
.create-category-form-buttons .MuiButton-root{
    min-width: 25%;
}

.create-cagegory-image{
    margin-top: 10px;
}

.MuiInputBase-input{
    display: flex !important;
}

.line{
    width: 100%;
    border: 1px solid #928585;
    margin-top: 20px;
}

.create-subArticle{
    display: flex;
}

.subArticle-arrow{
    margin-right: 18px;
}

.subArticle-arrow span{
    margin-top: 16px;
    color: #3d4c6f;
}

.delete-subArticle{
    margin: auto;
    margin-left: 18px;
}

.delete-subArticle span{
    cursor: pointer;
    font-size: 3rem;
    color: #3d4c6f;
}

.article-carrousel-list{
    width: 500;
    height: 450;
}

.article-imageSelected{
    max-height: 500px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.article-imageSelected-img{
    max-height: 500px;
}

.article-gridList{
    flex-wrap: nowrap !important;
    transform: 'translateZ(0)' !important;
}

.cart-image img{
    max-width: 200px;
}

.cart-article {
    display: flex;
    margin-right: 4%;
    margin-left: 4%;
}

.cart-article-total {
    display: flex;
    flex-direction: column;
    margin-right: 4%;
    margin-left: 4%;
}

.order-button {
    margin-bottom: 10px;
}

.cesta{
    margin: 2%;
    color: #000000de;
}

.delete-bin{
    cursor: pointer;
}

.delete-bin{
    cursor: pointer;
}

.cart-price{
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.cart-price-screen{
    margin-left: auto;
    display: flex;
    flex-direction: row;
}


.cart-article-detail{
    margin-left: 10px;
    color: #6b6b6b;
}

.cart-article-detail2{
    display: flex;
    flex-direction: column;
}

.article-cart-confirm{
    margin-top: 13px;
    color: green;
    font-size: larger;
}

.red-color{
    
}

.content-title{
    margin-left: 4%;
    color: #000000de;
}

.comb-disp{
    color: red;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-center h3{
    margin-top: 0;
    margin-bottom: 0;
}

.cart-price-mobile{
    display: none;
}

@media (max-width: 1600px) {
    .main-header-container { 
        width: 95%;
    }

    .admin-content-wrp{
        width: 50%;
    }
    
    .main-header-img{
        width: 20%;
    }
       
    .main-header-icons{
        width: 33%;
        display: flex;
        justify-content: space-around;
    }

    .main-header-search-container {
        width: 33%;
    }
}

@media (max-width: 1080px) { 
    .main-header-search-container {
        width: 33%;
    }

    .main-header-img {
        width: 16%;
    }

    .main-header-icons{
        width: 33%;
        font-size: small;
    }    
}

@media (max-width: 800px) {
    .main-header-search-container{
        width: 100%;
        margin-bottom: 10px;
    }

    .main-header-container ul{
        justify-content: space-between;
    }

    .main-header-container ul .dropdown{
        min-width: 33%;
        margin-right: 40px;
    }

    .footer-container {
        width: 100%;
        align-items: inherit;
        margin-left: 10px;
        font-size: small;
    }

    .route-title {
        margin-left: 4%;
    }
    .article-detail {
        flex-direction: column;
        width: 90%;
    }

    .admin-content-wrp{
        width: 95%;
    }

    .article-detail-form{
        align-items: center;
    }

    .article-img {
        max-width: 90%;
    }

    .main-header-img-mobile {
        display: block;
    }
    .main-header-img-mobile a{
        display: flex;
        justify-content: center;
    }
    .main-header-img-mobile img{
        width: 33%;
    }
    .main-header-scroll {
        display: none;
    }
    .main-header-img{
        display: none;
    }
    .main-header-container { 
        width: 100%;
        flex-direction: column;
    }
    .main-header-search{
        width: 100%;
        margin: 10px;
    }
    
    .main-header-img{
        width: 20%;
    }
       
    .main-header-icons{
        width: 100%;
        display: flex;
        justify-content: space-around;
        font-size: inherit;
    }
    .top-header {
        flex-direction: column;
    }

    .top-header-message {
        font-size: small;        
    }

    .MuiGridListTile-imgFullHeight {
        height: auto !important;
    }
    .article-gridList{
        height: auto !important;
    }

    .dropdown:hover .dropdown-content{
        display: none !important;
    }

    .cart-article {
        flex-direction: column;
    }

    .cart-price-screen {
        display: none;
    }

    .cart-price-mobile { 
        display: flex;
    }

    .create-category-form{
        width: 100%;
    }

    .mobile{
        display: block;
    }

    .screen {
        display: none;
    }
  }
